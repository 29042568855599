<template>
    <div class="popup" :class="{ 'is-active': true }" v-show="showFeedbacksModal && advisor">
        <div class="popup-container">
            <div class="popup-header" v-if="advisor">
                <div class="d-flex align-items-center">
                    <div style="flex: 1;">
                        <Link :href="'/advisor/' + advisor.url" class="content-header-title text-left">
                        {{ advisor.name }}
                        </Link>
                        <div class="d-flex justify-content-left">
                            <div>
                                <Link :href="'/advisor/' + advisor.url">
                                <Avatar :username="advisor.name" :src="advisor.avatar" :size="smallScreenMode ? 75 : 100" />
                                </Link>

                                <div class="pt-0">
                                    <p class="status is-offline is-text"
                                        v-if="lastOnline && advisor.status === 'offline'">Last online {{
                                            lastOnline }}</p>
                                    <p class="status" :class="'is-' + advisor.status" v-else>{{
                                        advisor.status }} <span class="status-dot"></span></p>
                                </div>
                            </div>
                            <div class="font-size-small d-flex align-items-center flex-column px-2">
                                <div class="pt-3">
                                    {{ Number(advisor.total_sessions).toLocaleString() }} sessions
                                </div>
                                <div>
                                    Accuracy score
                                </div>
                                <div class="badge badge-warning font-size-large">
                                    {{ formatAccuracy(advisor.accuracy) }}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <AdvisorChatButtons :advisor="advisor" :compactMode="smallScreenMode" :microMode="smallScreenMode" :hideViewButtons="true" v-if="!smallScreenMode" />
                    </div>
                    <div style="flex: 1;" class="align-self-start text-right">
                        <button @click="setShowFeedbacksModal(false)" type="button" class="btn btn-warning"
                            aria-label="Close">
                            Close
                        </button>

                        <AdvisorChatButtons :advisor="advisor" :compactMode="smallScreenMode" :microMode="smallScreenMode" :hideViewButtons="true" v-if="smallScreenMode" />
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="content-header-title ml-2">
                        Feedbacks ({{ userFeedBacksTotal }})
                    </div>
                    <div class="mr-2">
                        <Link :href="'/advisor/' + advisor.url">View Profile</Link>
                    </div>
                </div>

            </div>
            <div class="popup-scroll text-center" ref="feedbacksArea">

                <div class="card" v-if="advisor">
                    <AdvisorFeedback :advisorId="advisor.id" :detailsInHeads="true" :feedbacksBorder="true" :showTitle="false" />
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'
import { rootStore } from '@/src/store/root'

import AdvisorChatButtons from '@/src/components/AdvisorChatButtons.vue'
import AdvisorFeedback from '@/src/components/AdvisorFeedback.vue'
import Avatar from '@/src/components/Avatar.vue'
import Link from '@/src/components/Link.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
library.add(faCheck)

export default {
    data() {
        return {
            advisor: null,
        }
    },
    props: {
    },
    components: {
        FontAwesomeIcon,
        AdvisorChatButtons,
        AdvisorFeedback,
        Avatar,
        Link,
    },
    mounted() {
        this.$refs.feedbacksArea.addEventListener("scroll", this.feedbacksScroll)
    },
    unmounted() {
        if (this.$refs.feedbacksArea) {
            this.$refs.feedbacksArea.removeEventListener("scroll", this.feedbacksScroll)
        }
    },
    watch: {
        async feedbacksAdvisorId(value) {
            if (value) {
                this.advisor = await this.getUserByIdRaw(value)
                // Loading feedback from beginning, not best ones only.
                // this.loadMoreFeedback(this.advisor.id, true)
            }
        },
    },
    methods: {
        ...mapActions(usersStore, [
            'setShowFeedbacksModal',
            'loadMoreFeedback',
            'formatAccuracy',
            'getUserByIdRaw',
        ]),
        feedbacksScroll(event) {
            if (event.target.scrollTop + event.target.offsetHeight == event.target.scrollHeight) {
                if (this.userFeedBacksTotal > this.userFeedBacks.length) {
                    this.loadMoreFeedback(this.advisor.id)
                }
            }
        },
    },
    computed: {
        ...mapState(rootStore, [
            'smallScreenMode',
        ]),
        ...mapState(usersStore, [
            'userFeedBacks',
            'userFeedBacksTotal',
            'showFeedbacksModal',
            'feedbacksAdvisorId',
        ]),
    },
}
</script>

<style lang="scss" scoped>
@import "@/src/styles/variables";

h2 {
    color: var(--primary);
    font-weight: bold;
}

.popup {
    z-index: 1000000 !important;
}

.popup .popup-container {
    max-width: 50rem;
}

.popup-scroll {
    overflow-y: auto;
    max-height: calc(100% - 22rem);
    max-height: calc(100vh - 22rem);
    overflow-x: hidden;
    margin-right: -1rem;
    padding-right: 1rem;
}

.popup-container .popup-header {
    padding: 0.5rem;
    position: relative;
    margin: -0.75rem -0.75rem 0.25rem -0.75rem;
}

.popup-container .popup-header h5 {
    padding-top: 0.5rem;
    font-weight: bold;
}

.popup-container .popup-header .close.btn {
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
}

button.btn-success {
    background-color: $green;
    color: #ffffff;
}

@media screen and (max-width: $mobile) {
    .popup-scroll {
        overflow-y: auto;
        max-height: calc(100% - 21rem);
        max-height: calc(100vh - 21rem);
    }
}


</style>