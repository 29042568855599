<template>
    <div class="card">
        <div class="card-header font-weight-bold" v-if="showTitle">
            <h4 class="card-title font-weight-bold">Feedback</h4>
        </div>

        <div v-show="feedbacks && feedbacks.length" class="px-4">

            <template v-for="feedback in feedbacks">
                <div class="feedback-wrapper border-light border-bottom pb-3 mb-4" v-if="feedback?.client?.id"
                    :key="feedback.id" :class="{'border border-dark p-3 m-0': feedbacksBorder }">
                    <div class="text-end mb-1" :class="{'d-flex justify-content-between': detailsInHeads }">
                        <div class="" v-if="detailsInHeads">
                            <avatar :username="feedback.client.name" :src="feedback.client.avatar" :size="20"
                                style="display: inline-block;" />
                            {{ feedback.client.name }}
                        </div>
    
                        <div class="" v-if="detailsInHeads">
                            <RatingComponent :value="feedback.rate" :can-change="false" />
                        </div>
    
                        <time class="text-secondary font-small-3">{{ fromNow(feedback.updated_at) }}</time>
                    </div>
    
                    <p>{{ feedback.feedback }}</p>
    
                    <div class="row" v-if="!detailsInHeads">
    
                        <div class="col">
                            <RatingComponent :value="feedback.rate" :can-change="false" />
                        </div>
    
                        <div class="col text-right">
                            <avatar :username="feedback.client.name" :src="feedback.client.avatar" :size="20"
                                style="display: inline-block;" />
                            {{ feedback.client.name }}
                        </div>
                    </div>

                    <div class="advisor_reply" v-if="feedback.feedback_reply">
                        <strong>Advisor's reply:</strong> {{ feedback.feedback_reply }}
                    </div>
                    <div class="advisor_actions" v-if="iamAdvisor">
                        <button @click="(e) => openFeedbackReplyModal(e, feedback)">
                            <span v-if="feedback.feedback_reply">Edit your reply</span>
                            <span v-else>Write a reply</span>
                        </button>
                    </div>

                </div>
            </template>

            <button class="btn btn-outline-secondary" v-if="bestOnly" v-show="feedbacksTotal == 0 || feedbacksTotal > feedbacks.length">Show more</button>
            <button class="btn btn-outline-secondary" v-else v-show="feedbacksTotal == 0 || feedbacksTotal > feedbacks.length"
                @click="loadMoreFeedback(advisorId)">Show
                more</button>
        </div>

        <div class="card-body" v-show="!(feedbacks && feedbacks.length)">
            <p>No feedback found...</p>
        </div>

        <BootstrapModal :is-active="isActiveFeedbackReplyModal" heading="Write a feedback reply"
            @close="closeFeedbackReplyModal" @cancel="closeFeedbackReplyModal" @submit="saveFeedbackReply" submit-text="Save"
            :submit-disabled="!feedbackReplyForm.feedback_reply">
            <fieldset class="form-group">
                <textarea class="form-control" placeholder="Type a feedback reply here..."
                    v-model="feedbackReplyForm.feedback_reply"></textarea>
            </fieldset>
        </BootstrapModal>
    </div>
</template>


<style scoped lang="scss">
@import "@/src/styles/variables";
.advisor_reply {
    border-top: 1px solid $darker-gray;
    padding-top: 0.5rem;
    margin-top: 0.5rem;

    strong {
        font-size: 0.9rem;
    }
}

.advisor_actions {
    margin-top: 0.3rem;

    strong {
        font-size: 0.9rem;
    }

    p {
        margin: 0.5rem 0;
    }

    button {
        background-color: transparent;
        border: none;
        color: $blue;
        text-decoration: underline;
        font-size: 0.9rem;
        padding: 0;
    }
}
</style>


<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'
import { chatStore } from '@/src/store/chat'
import { TimeCalculations } from '@/src/helpers/timeCalculations'

import RatingComponent from '@/src/components/RatingComponent.vue'
import Avatar from '@/src/components/Avatar.vue'

import BootstrapModal from '@/src/components/BootstrapModal.vue'
import Form from '@/src/helpers/form-validation'

export default {
    data() {
        return {
            isActiveFeedbackReplyModal: false,
            feedbackReplyForm: new Form({
				'chat_id': null,
				'feedback_reply': '',
			}),
        }
    },
    props: {
        advisorId: {
            type: Number,
            required: true,
        },
        detailsInHeads: {
            type: Boolean,
            default: false,
        },
        feedbacksBorder: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        bestOnly: {
            type: Boolean,
            default: false,
        },
        feedbacksCount: {
            type: Number,
            default: 0,
        },
    },

    components: {
        RatingComponent,
        Avatar,
        BootstrapModal,
    },
    
    watch: {
        "advisorId"(value) {
            this.loadData()
        },
    },

    mounted() {
        this.loadData()
    },

    methods: {
        ...mapActions(usersStore, [
            'getFeedBacks',
            'getBestFeedBacks',
            'loadMoreFeedback',
            'setShowFeedbacks',
        ]),
        ...mapActions(chatStore, [
			'editFeedbackReply',
		]),
        loadData() {
            if (this.advisorId) {
                if (this.bestOnly) {
                    this.getBestFeedBacks(this.advisorId)
                } else {
                    if (this.feedbacksCount) {
                        this.setShowFeedbacks(this.feedbacksCount)
                    }
                    this.getFeedBacks(this.advisorId)
                }
            }
        },
        fromNow(time) {
            return TimeCalculations.getTimeFromNow(time)
        },
        // open feedback reply modal
		openFeedbackReplyModal(e, feedback) {
            e.stopPropagation()

			this.feedbackReplyForm.chat_id = feedback.chat_id
			this.feedbackReplyForm.feedback_reply = feedback.feedback_reply
			this.isActiveFeedbackReplyModal = true;
		},
		// close feedback reply modal
		closeFeedbackReplyModal() {
			this.isActiveFeedbackReplyModal = false;
		},
		// save feedback reply
		saveFeedbackReply() {
			this.editFeedbackReply(this.feedbackReplyForm)
				.then(() => {
                    this.loadData()
					this.closeFeedbackReplyModal();
				})
		},
    },

    computed: {
        ...mapState(usersStore, [
            // 'userBestFeedBacksPage',
            'userBestFeedBacks',
            'userBestFeedBacksTotal',
            'userFeedBacks',
            'userFeedBacksTotal',
            'currentUser',
        ]),
        feedbacks() {
            if (this.bestOnly) {
                return this.userBestFeedBacks
            } else if (this.userFeedBacks) {
                if (this.feedbacksCount) {
                    return this.userFeedBacks.slice(0, this.feedbacksCount)
                } else {
                    return this.userFeedBacks
                }
            } else {
                return []
            }
        },
        feedbacksTotal() {
            if (this.bestOnly) {
                return this.userBestFeedBacksTotal
            } else {
                return this.userFeedBacksTotal
            }
        },
        iamAdvisor() {
            return this.currentUser?.id == this.advisorId
        }
    }
}
</script>
