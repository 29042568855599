import {computed, ref} from 'vue';
import {getWindow} from "@/src/shared/helpers/getWindow";
import {theme} from "@/theme";

const useAdvisorListPaging = () => {
    const {windowWidth} = getWindow();
    const cardName = theme.home.advisor?.cardName;
    const page = ref(1);
    const total = ref(0);
    const perPage = computed(() => {
        if (windowWidth.value > 1440 && cardName === 'cover-image') return 8;
        else return 6;
    })
    const nextPage = () => {
        page.value += 1
    }
    const resetPage = () => {
        page.value = 1
    }
    const setTotal = (newTotal: any) => {
        total.value = newTotal
    }

    return {page, total, perPage, nextPage, resetPage, setTotal}
}

export {
    useAdvisorListPaging,
}