<template>
  <se-static-home-header :total-reviews="totalReviews" />
  <suspense>
    <se-static-home-top-advisors :total-sessions="totalSessions" class="bg-neutral-10" />
  </suspense>
  <se-static-home-categories />
  <se-static-home-new-advisors />
  <se-static-home-promo />
  <se-static-home-reasons :total-reviews="totalReviews" :total-sessions="totalSessions" />
  <se-static-home-testimonials v-if="topReviews" :reviews="topReviews" class="bg-neutral-10" />
  <se-static-home-video-reviews />
  <se-static-home-how-to-start />
  <se-static-home-about />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import { nFormatter } from '@/src/helpers/numberFormatting';
import SeStaticHomeTopAdvisors from '../components/sections/home/top-advisors.home';
import SeStaticHomeCategories from '../components/sections/home/categories.home';
import SeStaticHomeNewAdvisors from '../components/sections/home/new-advisors.home';
import SeStaticHomePromo from '../components/sections/home/promo.home';
import SeStaticHomeReasons from '../components/sections/home/reasons.home';
import SeStaticHomeTestimonials from '../components/sections/home/testimonials.home';
import SeStaticHomeHowToStart from '../components/sections/home/how-to-start.home';
import SeStaticHomeAbout from '../components/sections/home/about.home';
import SeStaticHomeHeader from '../components/sections/home/header.home';
import SeStaticHomeVideoReviews from "@/src/modules/static/components/sections/home/video-reviews.home.vue";

const { $api } = getGlobalProperties();
const totalSessions = ref('');
const totalReviews = ref('');
const topReviews = ref([]);

await $api.get('/stats')
  .then((res: any) => {
    totalSessions.value = nFormatter(res.data.total_sessions, 1);
    totalReviews.value = nFormatter(res.data.total_reviews, 1);
    topReviews.value = res.data.reviews;
  })
  .catch((err: any) => {
    console.log(err, 'error happened');
  });

</script>

<script lang="ts">
export default {
  name: 'SeStaticHomePage',
};
</script>
