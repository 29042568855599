<template>
    <div class="popup" :class="{ 'is-active': notifyMeAbout !== null }"
        v-show="currentUser && currentUser.role_name === 'user'">
        <div class="popup-container">
            <div class="popup-heading" v-show="notifyMeAbout !== null">
                <Avatar style="display: inline-block;" :username="notifyMeAboutName" :src="notifyMeAboutAvatar"
                    :size="20" />
                <span>{{ notifyMeAboutName }}</span>
            </div>
            <h3 class="popup-heading" v-show="notifyMeAbout === null">Notify me</h3>

            <ul class="notify">
                <li>One time notification</li>
                <li class="is-text-muted"><i>You will be notified one time, when advisor will come online.</i></li>
                <li>Everytime notification</li>
                <li class="is-text-muted"><i>You will be notified every time, when advisor will come online.</i></li>
            </ul>
            <div class="field notify">
                <div class="control">
                    <label class="radio">
                        <input type="radio" name="question" v-model="type" value="onetime">
                        One time
                    </label>
                    <label class="radio">
                        <input type="radio" name="question" v-model="type" value="everytime">
                        Everytime
                    </label>
                </div>
            </div>
            <div class="buttons-wrapper">
                <button class="btn btn-danger" @click="closeModal">Cancel</button>
                <button class="btn btn-success" @click="notifyMe()" :disabled="type === null"
                    :class="{ 'is-loading': loading }">
                    Notify me
                </button>
            </div>
        </div> <!-- popup-container -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'

import Avatar from '@/src/components/Avatar.vue'

export default {
    data() {
        return {
            type: null,
            loading: false
        }
    },
    components: {
        Avatar,
    },
    methods: {
        ...mapActions(usersStore, [
            'setNotifyMeAbout',
        ]),
        closeModal() {
            this.setNotifyMeAbout(null);
            this.type = null;
        },
        notifyMe() {
            this.loading = true;
            this.$api.post(`/api/user/${this.notifyMeAbout.id}/notify`, { type: this.type })
                .then(res => {
                    this.loading = false;
                    this.type = null;
                    this.setNotifyMeAbout(null);
                    this.currentUser.notify.push(res.data);
                })
                .catch(error => {
                    console.error(error.response);
                    this.loading = false;
                    this.type = null;
                    this.setNotifyMeAbout(null);
                })
        }
    },
    computed: {
        ...mapState(usersStore, [
            'currentUser',
            'notifyMeAbout',
        ]),
        notifyMeAboutName() {
            return this.notifyMeAbout?.name ?? ''
        },
        notifyMeAboutAvatar() {
            return this.notifyMeAbout?.avatar ?? ''
        },
    }
}
</script>

<style lang="scss">
.field.notify label {
    margin-left: 1em;
    margin-right: 1em;
}
.popup {
    height: 100vh;
}
</style>
