// fetch wrapper emulating axios behavior.
export default {
    defaults: {
        baseURL: '',
        authHeaders: {},
        timeout: 100000,
    },
    app: null,
    get: async function (url, data = null, options = {}) {
        if (data) {
            var dummyDomain = 'http://dummy.domain'
            var urlObject = new URL(dummyDomain + url);
            for (let fieldName in data) {
                urlObject.searchParams.set(fieldName, data[fieldName])
            }
            url = urlObject.toString().replace(new RegExp(`^${dummyDomain}`), '')
        }
        return this.fetch(url, 'GET', null, options)
    },
    post: async function (url, data, options = {}) {
        return this.fetch(url, 'POST', data, options)
    },
    put: async function (url, data, options = {}) {
        return this.fetch(url, 'PUT', data, options)
    },
    delete: async function (url, options = {}) {
        return this.fetch(url, 'DELETE', null, options)
    },
    fetch: async function (srcUrl, method, data, options) {
        let url = srcUrl || ''
        if (url && typeof url === 'string' && !url.match(/^https?:/) && !url.startsWith('/static')) {
            url = this.defaults.baseURL + url
        }

        let headers = options?.headers ?? {}
        headers['Accept'] = 'application/json'
        if (['POST', 'PUT'].includes(method)) {
            headers['Content-Type'] = 'application/json'
        }

        for (let i in this.authHeaders) {
            headers[i] = this.authHeaders[i]
        }

        let fetchOptions = {
            method: method,
            headers: headers,
            redirect: 'manual',
        }
        if (data !== null) {
            if (data instanceof FormData) {
                fetchOptions.body = data
                delete headers['Content-Type']
            } else {
                fetchOptions.body = JSON.stringify(data)
            }
        }

        const successCallback = async function (res) {
            const status = res.status
            if ([200, 201, 204].includes(status)) {
                return {
                    data: res?.headers?.get("content-type").includes('json') ? await res.json() : res,
                    status: status,
                }
            } else if ([473].includes(status)) {
                // This API method requires to verify that the user is that user.
                if (this.app?.$auth?.verifyUser && await this.app.$auth.verifyUser()) {
                    return this.fetch(srcUrl, method, data, options)
                } else {
                    throw {
                        text: "The verification is required.",
                        status: status,
                    }
                }
            } else if ([474].includes(status)) {
                // This API method requires to verify that the user has input and verified the identity.
                if (this.app?.$auth?.requireIdentity && await this.app.$auth.requireIdentity()) {
                    return this.fetch(srcUrl, method, data, options)
                } else {
                    throw {
                        text: "The identity is required.",
                        status: status,
                    }
                }
            } else if ([475].includes(status)) {
                // This API method requires prohibits further operation due to profile should be approved to continue.
                this.app?.$toast({
                    message: 'Please wait until the administrator approves your profile.',
                    type: 'error',
                    icon: 'ion-close',
                    duration: 10000
                })
            } else if ([401].includes(status)) {
                // Authentication token is expired.
                if (srcUrl != '/auth/check-token') {
                    await this.app.$auth.refresh()
                } else {
                    this.app.$auth.clearToken()
                    this.app.$router.push({ path: '/login' })
                }
                return {
                    data: res?.headers?.get("content-type").includes('json') ? await res.json() : res,
                    status: status,
                }
            } else {
                const text = await res.text()
                let json = null
                if (text[0] == '{') {
                    try {
                        json = JSON.parse(text)
                    } catch (e) { }
                }
                throw {
                    text: text,
                    json: json,
                    status: status,
                }
            }
        }.bind(this)

        const failureCallback = function (error) {
            throw {
                status: error.status,
                response: (error?.json ? error?.json : (error?.text ? error?.text : error))
            };
        }

        let retry = true
        const abortController = new AbortController()
        if (import.meta.env.SSR) {
            const timeoutId = setTimeout(function () { abortController.abort() }, this.defaults.timeout)
        }
        fetchOptions.signal = abortController.signal
        return await fetch(url, fetchOptions)
            .then((res) => successCallback(res))
            .catch(async (error) => {
                // Retrying once.
                if (retry && ![475, 474, 473].includes(error?.status)) {
                    retry = false

                    const abortController = new AbortController()
                    if (import.meta.env.SSR) {
                        const timeoutId = setTimeout(function () { abortController.abort() }, this.defaults.timeout)
                    }
                    fetchOptions.signal = abortController.signal
                    return await fetch(url, fetchOptions)
                        .then((res) => successCallback(res))
                        .catch(async (error) => {
                            failureCallback(error)
                        })
                } else {
                    failureCallback(error)
                }
            })
    },
    setAuthHeaders: function (headers) {
        this.authHeaders = headers
    },
    setApp: function (app) {
        this.app = app
    },
}
