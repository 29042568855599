<template>
  <section v-if="videoReviews" class="bg-neutral-10">
    <div class="container py-32 md:py-56 lg:py-88">
      <h2 class="text-h-xl md:text-h-2xl font-semibold pb-16 md:pb-32">
        {{ videoReviews?.title }}
      </h2>
      <div>
        <div class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="video in videoReviewItems">
              <div class="flex flex-col h-full">
                <iframe :src="`https://www.youtube.com/embed/${video.id}`" class="w-full h-full rounded-12 md:rounded-24" frameborder="0" allow="encrypted-media; picture-in-picture" allowfullscreen ></iframe>
                <div class="flex items-center gap-1 mt-10 justify-start">
                  <se-avatar :id="video.id" :name="video.username" :size="24"/>
                  @{{video.username}}
                </div>
              </div>

            </div>

          </div>
          <div class="lg:hidden relative flex justify-center items-center !mt-20">
            <se-button icon-only class="custom-prev" @click="swiper.slidePrev()">
              <se-icon name="chevron-left"></se-icon>
            </se-button>
            <se-button icon-only class="custom-next" @click="swiper.slideNext()">
              <se-icon name="chevron-right"></se-icon>
            </se-button>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { theme } from '@/theme';
import { Swiper } from "swiper";
import {onMounted, ref} from "vue";
import SeAvatar from "@/src/shared/ui-kit/avatar/Avatar.vue";
import SeButton from "@/src/shared/ui-kit/button/Button.vue";
import SeIcon from "@/src/shared/ui-kit/icon/Icon.vue";
const { videoReviews } = theme.home;

const swiper = ref();

onMounted(() => {
  swiper.value = new Swiper(".mySwiper", {
    slidesPerView: 1,
    spaceBetween: 30,
    centeredSlides: false,
    breakpoints: {
      1200: {
        slidesPerView: 4,
      },
      1000: {
        slidesPerView: 3,
      },
      769: {
        slidesPerView: 2,
      },
    }
  });

  setTimeout(() => {
    swiper.value?.init();
  }, 500);
})

const videoReviewItems = [
  {
    videoUrl: 'https://youtube.com/shorts/sQguDIrJ_DU?feature=share',
    id: 'sQguDIrJ_DU',
    username: 'veganbeautyguru',
    image: ''
  },
  {
    videoUrl: 'https://youtube.com/shorts/8cX0As7A8fA?feature=share',
    id: '8cX0As7A8fA',
    username: 'curatedbyjennifer',
    image: ''
  },
  {
    videoUrl: 'https://youtube.com/shorts/OMEWEhMFFU4?feature=share',
    id: 'OMEWEhMFFU4',
    username: 'writesarahhoney',
    image: ''
  },
  {
    videoUrl: 'https://youtube.com/shorts/gyBlcH49b7A?feature=share',
    id: 'gyBlcH49b7A',
    username: 'rachelles_wheels',
    image: ''
  },
]

</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeVideoReviews',
};
</script>
<style lang="scss">
.swiper {
  width: 100%;
  height: 620px;
  padding-bottom: 60px !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
