import { defineStore } from 'pinia'
import { TimeCalculations } from '@/src/helpers/timeCalculations'
import { getStorageValue, setStorageValue, removeStorageValue } from '@/src/helpers/permanentValueStorage'

const COOKIES_ACCEPTED_COOKIE_NAME = 'cookies'

export const rootStore = defineStore('root', {
	//app statements
	state: () => ({
		counter: 0,
		remotePageContent: null,
		error: null,
		//promotion landing
		promotionLandingFlag: false,
		promotionLandingData: null,
		promotionLandingFailed: false,
		// sockets subscription
		is_subscribed: false,
		//time
		timeDifference: 0,
		// window related things
		showSideBarMenu: false,
		windowHeight: 1000,
		windowWidth: 1000,
		smallScreenMode: false,
		advisorCardsPerRow: 1, // Starting with 1 to lower vertical jumps on small screen.
		showCookieWarning: false, 
	}),
	getters: {
		cookiesAllowed() {
			// return getStorageValue(COOKIES_ACCEPTED_COOKIE_NAME) !== 'no'
			return getStorageValue(COOKIES_ACCEPTED_COOKIE_NAME) === 'yes'
		},
	},
	actions: {
		onClient() {
			return !import.meta.env.SSR;
		},
		setIsSubscribed(value) {
			this.is_subscribed = value
		},
		setPromotionLandingData(value) {
			this.promotionLandingData = value
		},
		setPromotionLandingDataFailed(value) {
			this.promotionLandingFailed = value
		},
		setPromotionLandingFlag(value) {
			this.promotionLandingFlag = value
		},
		setTimeDifference(value) {
			this.timeDifference = value
		},
		setWindowHeight(value) {
			this.windowHeight = value
		},
		setWindowWidth(value) {
			this.windowWidth = value
		},
		setSmallScreenMode(value) {
			this.smallScreenMode = value
		},
		setAdvisorCardsPerRow(value) {
			this.advisorCardsPerRow = value
		},
		toggleSideBarMenu() {
			this.showSideBarMenu = !this.showSideBarMenu
		},
		hideSideBarMenu() {
			this.showSideBarMenu = false
		},
		setShowCookieWarning(value) {
			this.showCookieWarning = value
		},
		readCookieWarning() {
			this.setShowCookieWarning(!getStorageValue(COOKIES_ACCEPTED_COOKIE_NAME))
		},
		disableCookies() {
			this.setShowCookieWarning(false)
			setStorageValue(COOKIES_ACCEPTED_COOKIE_NAME, 'no', 365)
			window.location.reload(true)
		},
		acceptCookies() {
			this.$analytics.updateConsent()
			this.setShowCookieWarning(false)
			setStorageValue(COOKIES_ACCEPTED_COOKIE_NAME, 'yes', 365)
		},
		enableCookieWarning() {
			this.setShowCookieWarning(true)
			removeStorageValue(COOKIES_ACCEPTED_COOKIE_NAME)
		},
		async getPromotionLandingData() {
			this.setPromotionLandingFlag(true)
			this.setPromotionLandingDataFailed(false)
			const _this = this
			return await this.$api.get('/promotion-landing')
				.then(async res => {
					_this.setPromotionLandingData(res.data)
					return true
				})
				.catch(err => {
					console.error(err, err.response)
					_this.setPromotionLandingDataFailed(true)
				})
		},
		getTimeDifference() {
			this.$api.get('/time')
				.then(res => {
					let diff = TimeCalculations.getCurrentUnixTime() - res.data
					// if (diff < 10 && diff > -10)
					// 	diff = 0;
					this.setTimeDifference(diff)
				}).catch(error => {

					if (error.response && error.response.status) {

						let redirectToMaintenance = error.response.status === 503;
						let onMaintenancePage = window.location.pathname === '/maintenance';

						if (redirectToMaintenance && !onMaintenancePage) {
							window.location = '/maintenance'
						}
					}
				})
		},
		
		// /** Initializes time synchronization.
		// */
		// startTimeSynchronization() {
		// 	return Promise.all([
		// 		this.getTimeDifference(),
		// 		(async () => {
		// 			let _this = this
		// 			let timer = setInterval(function () {
		// 				if (!_this.$auth.check()) {
		// 					clearInterval(timer)
		// 				} else {
		// 					_this.getTimeDifference()
		// 					// users.getOnlineUsers()
		// 				}
		// 			}, 30000)
		// 		})(),
		// 	])
		// },

	}
})
