<template>
  <component :is="advisorCardMap[advisor.cardName]" v-bind="$attrs"></component>
</template>

<script setup lang="ts">
import { theme } from '@/theme';
import SeAdvisorCardStretched from "@/src/modules/advisor/components/advisor-card-types/advisor-card-stretched.vue";
import SeAdvisorCardSmallImage from "@/src/modules/advisor/components/advisor-card-types/advisor-card-small-image.vue";
import SeAdvisorCardCoverImage from "@/src/modules/advisor/components/advisor-card-types/advisor-card-cover-image.vue";

const {advisor} = theme.home

const advisorCardMap = {
  'stretched': SeAdvisorCardStretched,
  'small-image': SeAdvisorCardSmallImage,
  'cover-image': SeAdvisorCardCoverImage
}

</script>

<script lang="ts">
export default {
  name: 'SeAdvisorCard',
};
</script>