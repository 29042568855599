// Import Sentry synchronously at the top level
import * as Sentry from '@sentry/vue';
import sentryRouterWrapper from '@/src/helpers/sentryRouter';
import config from '@/config';

// Function to initialize Sentry
function initializeSentry(app) {
    try {
        if (!import.meta.env.SSR) {
            const apiUrl = config.api.url;
            const appDomain = import.meta.env.APP_DOMAIN;
            let tracePropagationTargets = [];
    
            function escapeForRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            }
    
            if (apiUrl) {
                const apiUrlObj = new URL(apiUrl);
                const apiOrigin = apiUrlObj.origin;
                const apiPattern = new RegExp('^' + escapeForRegExp(apiOrigin) + '(/|$)');
                tracePropagationTargets.push(apiPattern);
            }
    
            if (appDomain) {
                const appDomainPattern = new RegExp('^' + escapeForRegExp(appDomain) + '(/|$)');
                tracePropagationTargets.push(appDomainPattern);
            }
    
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
                integrations: [
                    Sentry.browserTracingIntegration({ sentryRouterWrapper }),
                    Sentry.replayIntegration(),
                ],
                tracesSampleRate: 1.0,
                tracePropagationTargets: tracePropagationTargets,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0
            });
            console.log('[Sentry] Initialization succeeded!');
        } else {
            console.log('[Sentry] Running in SSR, skipping initialization');
        }
    } catch (e) {
        console.error('[Sentry] Initialization failed:', e);
        console.error('[Sentry] Error details:', {
            name: e.name,
            message: e.message,
            stack: e.stack
        });
    }
}

export { initializeSentry };