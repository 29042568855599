<template>
  <Link :href="`/advisor/${advisor.url}`" class="advisor-card hidden lg:visible lg:block link text-black no-underline h-full w-full">
    <se-card v-if="advisor" class="h-full p-24 flex gap-12 lg:gap-32 transition overflow-hidden hover:shadow-large">
      <div class="max-h-[240px] relative shrink-0">
        <se-avatar :id="advisor.id" :name="advisor.name" :src="advisor.avatar" :size="240"
                   class="!rounded-[12px]"/>
        <div class="absolute bottom-16 left-16 flex z-10">
          <se-advisor-status :advisor="advisor"/>
        </div>
      </div>
      <div class="px-[10px] 3xs:px-16 flex flex-col justify-between w-full">
        <div class="flex pb-16">
          <div class="flex flex-col flex-grow-1">
            <h3 class="text-h-m font-bold !mb-8 line-clamp-1">
              {{ advisor.name }}
            </h3>
            <se-advisor-accuracy :advisor="advisor" :reviews-count="advisor.reviews_count"/>
          </div>
        </div>
        <div class="text-h-m text-neutral-80 mb-16 line-clamp-3">
          <span v-if="advisor.short_description">{{ advisor.short_description }}</span>
          <span v-else>This advisor doesn’t have any description yet</span>
        </div>
        <div class="flex-grow"/>
        <se-advisor-categories v-if="advisor.profile_categories?.length" :advisor="advisor" class="grid"/>
        <div class="flex justify-start items-center gap-2 font-medium text-[12px]">
          <p>View Full Profile</p>
          <se-icon name="chevron-right" :size="14" class="font-medium"/>
        </div>
      </div>
      <div class="w-[238px] lg:w-[268px] shrink-0 advisor-card-stretched-actions" :class="{'!w-[290px]': !isOnline}">
        <template v-if="isOnline" class="flex justify-between flex-col">
          <div
              v-if="isBlackFridayActive"
              class="flex items-center text-white gap-2 rounded-[8px] py-2 overflow-hidden"
              :style="{backgroundImage: 'url(' + '/static/images/promo/bf-bg.png' + ')'}"
          >
            <div class="bg-purple-100 w-fit font-bold italic px-16 py-1 ribbon half text-h-xs xs:text-h-s">
              <p class="whitespace-nowrap">BLACK FRIDAY</p>
            </div>
            <div class="whitespace-nowrap text-h-s">
              <span v-if="advisor.free_minutes_left">{{ advisor.free_minutes_left }} Free min</span>
              <span v-if="advisor.discount">&nbsp;•&nbsp;{{ advisor.discount - 10 }}%+10% OFF</span>
            </div>
          </div>
          <div v-else-if="advisor.free_minutes_left || advisor.discount" class="bg-primary-10 rounded-[8px] text-neutral-100 text-center p-2 mb-16 font-medium">
            <span v-if="advisor.free_minutes_left">Start {{ advisor.free_minutes_left }} min Free Chat</span>
            <span v-else>Start Session</span>
            <span v-if="advisor.discount">&nbsp;&nbsp;•&nbsp;&nbsp;{{ advisor.discount }}% OFF</span>
          </div>
          <se-advisor-card-chat-options :is-expanded="true" :advisor="advisor"/>
        </template>
        <template v-else>
          <div class="bg-neutral-10 rounded-8 flex items-center justify-between p-12 mb-16">
            <div class="flex items-center gap-[6px]">
              <div v-if="advisor.video_enabled">
                <se-icon name="video" :size="24" :class="isOnline ? 'text-primary-100' : 'text-neutral-50'"/>
              </div>
              <div v-if="advisor.audio_enabled">
                <se-icon name="phone" :size="20" :class="isOnline ? 'text-feature-100' : 'text-neutral-50'"/>
              </div>
              <div v-if="advisor.text_enabled">
                <se-icon name="message-circle" :size="20" :class="isOnline ? 'text-love-100' : 'text-neutral-50'"/>
              </div>
            </div>
            <div class="flex justify-right items-center">
              <se-advisor-general-price :advisor="advisor"/>
            </div>
          </div>
          <div class="flex items-center gap-8">
            <se-advisor-offline-options :advisor="props.advisor"/>
          </div>
        </template>
      </div>
    </se-card>
  </Link>
  <se-advisor-card-small-image categories-visible :advisor="advisor" class="lg:hidden"/>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {useBlackFriday} from "@/src/shared/helpers/promoBanner";
import SeAvatar from '@/src/shared/ui-kit/avatar/Avatar';
import SeCard from '@/src/shared/ui-kit/card/Card';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import Link from '@/src/components/Link';
import SeAdvisorCardChatOptions from "@/src/modules/advisor/components/shared/advisor-card-chat-options.vue";
import SeAdvisorOfflineOptions from "@/src/modules/advisor/components/shared/advisor-offline-options.vue";
import SeAdvisorGeneralPrice from "@/src/modules/advisor/components/shared/advisor-general-price.vue";
import SeAdvisorAccuracy from "@/src/modules/advisor/components/shared/advisor-accuracy.vue";
import SeAdvisorStatus from "@/src/modules/advisor/components/shared/advisor-status.vue";
import SeAdvisorCategories from "@/src/modules/advisor/components/shared/advisor-categories.vue";
import SeAdvisorCardSmallImage from "@/src/modules/advisor/components/advisor-card-types/advisor-card-small-image.vue";

const props = defineProps<{
  advisor: any
}>();

const {isBlackFridayActive} = useBlackFriday()
const isOnline = computed(() => props.advisor.status === 'online');
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorCardStretched',
};
</script>

<style lang="scss">
.advisor-card-stretched-actions {
  @apply flex flex-col justify-between;
  .c-btn {
    margin: 0 !important;
  }
}
</style>

<style lang="scss" scoped>

.ribbon {
  --f: 0px; /* control the folded part*/
  --r: .6em; /* control the ribbon shape */
  
  padding-inline: 12px;
}

.half {
  left: 0;
  border-right: var(--r) solid #0000;
  clip-path: polygon(
      100% 0,
      0 0,
      0 calc(100% - var(--f)),
      var(--f) calc(100% - var(--f)),
      100% calc(100% - var(--f)),
      calc(100% - var(--r)) calc(50% - var(--f) / 2)
  );
}

</style>