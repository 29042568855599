<template>
	<transition name="modal">
		<div v-if="notEnoughMoney || notEnoughMoneyInbox" :class="{ 'is-loading': loading }">
			<div class="modal" tabindex="-1" role="dialog" aria-labelledby="Modal" style="display: block">
				<div class="modal-dialog modal-md" role="document" @click.stop="">
					<div class="modal-content">

						<div class="modal-header bg-success white">
							<h5 class="modal-title" v-if="currentUser && currentUser.amount !== undefined">Your balance: ${{
								Number(currentUser.amount).toFixed(2) }}</h5>
							<button type="button" class="close btn" aria-label="Close" @click="closeThisModal">
								<span aria-hidden="true"><font-awesome-icon icon="times" /></span>
							</button>
						</div>
						<div class="modal-body">
							<p>
								<b>Low Balance!</b> Please add funds to use this offer. Funds left will be waiting for your
								next sessions or can
								be redeemed later.
							</p>
							<AddFundsButtons :advisorId="advisor.id" :chatMode="chatMode" :defaultToFirst="true" />
						</div>
					</div>
				</div>
			</div>

			<div class="modal-backdrop show"></div>
		</div>
	</transition>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'
import { chatStore } from '@/src/store/chat'
import { inboxStore } from '@/src/store/inbox'
import _ from '@/src/helpers/arrayHelpers'

import AddFundsButtons from '@/src/components/AddFundsButtons.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
library.add(faTimes)

export default {
	data() {
		return {
			loading: false,
			custom_amount: 3,
			advisor: {},
		}
	},
	components: {
		AddFundsButtons,
		FontAwesomeIcon,
	},
	mounted() {
		if (this.savedAdvisor) {
			this.getAdvisor();
		}
	},
	watch: {
		notEnoughMoney(val) {
			if (val)
				this.getAdvisor();
		},
		notEnoughMoneyInbox(val) {
			if (val)
				this.getAdvisor();
		}
	},
	methods: {
		...mapActions(chatStore, [
			'setNotEnoughMoney',
			'getChatModeByAction',
		]),
		...mapActions(inboxStore, [
			'openMessageModal',
			'setNotEnoughMoneyInbox',
		]),
		...mapActions(usersStore, [
			'setChargeAmount',
		]),
		getAdvisor() {
			if (this.savedParam === null && this.savedAdvisor?.id) {
				this.advisor = this.savedAdvisor;
				return false;
			}

			this.loading = true;
			this.$api.get(`/user/${this.savedParam}`)
				.then(res => {
					this.advisor = res.data;
					this.loading = false;
				})
				.catch(error => {
					this.loading = false;
				})
		},
		closeThisModal() {
			this.setNotEnoughMoney(false)
			this.setNotEnoughMoneyInbox(false)
			this.setChargeAmount(null)
			// if (this.$route.path == '/dashboard/chat') {
			// 	// Closing the active chat not to let it stuck if user cancels the payment.
			// 	if (this.currentUser.active_chat) {

			// 	}
			// 	this.$router.push('/dashboard')
			// }
		},
		openInboxMessageModal(id) {
			this.$api.get(`/user/${id}`)
				.then(res => this.openMessageModal(res.data))
		}
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
			'promotions',
		]),
		...mapState(chatStore, [
			'notEnoughMoney',
			'savedParam',
			'savedAdvisor',
			'savedAction',
			'chatInfo',
		]),
		...mapState(inboxStore, [
			'notEnoughMoneyInbox',
			'sendingMessage',
			'message',
		]),
		chatMode() {
			return this.chatInfo?.mode ? this.chatInfo.mode : (this.savedAction ? this.getChatModeByAction(this.savedAction).mode : '')
		},
	}
}
</script>

<style lang="scss" scoped>
.el-select {
	width: 100%;
	margin: 10px 0;
}

.modal {
	overflow-x: hidden;
	overflow-y: auto;
}

button.close {
	cursor: pointer;
}

/* Transitions */
.modal-enter-active,
.modal-leave-active {
	transition: opacity .3s linear;
}

.modal-enter-active .modal,
.modal-leave-active .modal,
.modal-enter-active .modal-backdrop,
.modal-leave-active .modal-backdrop {
	transition: opacity .15s linear;
}

.modal-enter .modal,
.modal-leave-to .modal,
.modal-leave-active .modal,
.modal-enter .modal-backdrop,
.modal-leave-to .modal-backdrop,
.modal-leave-active .modal-backdrop {
	opacity: 0;
}

.modal-enter-active .modal-dialog,
.modal-leave-active .modal-dialog {
	transition: transform .3s ease-out;
}

.modal-enter .modal-dialog,
.modal-leave-to .modal-dialog,
.modal-leave-active .modal-dialog {
	transform: translate(0, -25%);
}</style>
