<template>
	<ul class="notifications-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right"
		:class="{ 'is-loading': loading }">
		<li class="dropdown-menu-header">
			<h6 class="dropdown-header m-0">
				<span class="grey darken-2">Notifications</span>
				<span class="notification-tag badge badge-default badge-danger float-right m-0"
					v-show="unreadNotifications">{{ onClient() ? unreadNotifications : '' }} New</span>
			</h6>
		</li>
		<li class="scrollable-container media-list" id="notifications-container">
			<div v-for="notification in currentUser.notifications" @mouseover="submitReadNotification(notification)">
				<Link :href="notification.url" v-if="notification.url">
				<div class="media" :class="{ 'is-unread': notification.is_unread }">
					<div class="media-left align-self-center">
						<font-awesome-icon icon="bell" class="icon-bg-circle bg-cyan" />
					</div>
					<div class="media-body">
						<!--<h6 class="media-heading">You have new order!</h6>-->
						<p class="notification-text font-small-3 text-muted" v-html="notification.body"></p>
						<small>
							<time datetime="2015-06-11T18:29:20+08:00" class="media-meta text-muted">
								{{ formatDate(notification.created_at) }}
							</time>
						</small>
					</div>
				</div>
				</Link>
				<a href="javascript:void(0)" @mouseover="submitReadNotification(notification)" v-else>
					<div class="media" :class="{ 'is-unread': notification.is_unread }">
						<div class="media-left align-self-center">
							<font-awesome-icon icon="bell" class="icon-bg-circle bg-cyan" />
						</div>
						<div class="media-body">
							<!--<h6 class="media-heading">You have new order!</h6>-->
							<p class="notification-text font-small-3 text-muted" v-html="notification.body"></p>
							<small>
								<time datetime="2015-06-11T18:29:20+08:00" class="media-meta text-muted">
									{{ formatDate(notification.created_at) }}
								</time>
							</small>
						</div>
					</div>
				</a>
			</div>
		</li>
		<li class="dropdown-menu-footer">
			<a href="javascript:" class="dropdown-item text-muted text-center" v-show="unreadNotifications > 0"
				@click="markAllAsRead">
				Mark all as read
			</a>
		</li>
		<li class="dropdown-menu-footer">
			<Link href="/dashboard/notifications" class="dropdown-item text-muted text-center">
			View all notifications
			</Link>
		</li>
	</ul>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'
import { rootStore } from '@/src/store/root'
import { TimeCalculations } from '@/src/helpers/timeCalculations'

import Link from '@/src/components/Link.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell } from '@fortawesome/free-solid-svg-icons'
library.add(faBell)

export default {
	name: "notifications-drop-down",
	data() {
		return {
			ps: null,
			loading: false,
		}
	},
	components: {
		Link,
		FontAwesomeIcon,
	},
	// mounted() {
	// 	const container = document.querySelector('#notifications-container');
	// 	if (container)
	// 		this.ps = new PerfectScrollbar(container, {
	// 			theme: 'dark'
	// 		})
	// },
	methods: {
		...mapActions(usersStore, [
			'submitReadNotification',
			'readAllNotifications',
			'unreadNotification',
		]),
        ...mapActions(rootStore, [
            'onClient',
        ]),
		formatDate(time) {
			return TimeCalculations.rawFormat(time, 'dddd, MMMM Do YYYY, H:mm:ss')
		},
		markAllAsRead() {
			this.loading = true;
			return this.$api.post(`/api/notifications/read-all`, {})
				.then(res => {
					this.readAllNotifications();
					this.loading = false;
				})
				.catch((err) => {
					this.loading = false;
					console.error(err, err.response)
				})
		},
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
		]),
		unreadNotifications() {
			if (!this.currentUser)
				return 0;
			return this.currentUser.total_unread_notifications;
		},
		sortedNotifications() {
			const unread = this.currentUser.notifications.filter(item => item.is_unread);
			const read = this.currentUser.notifications.filter(item => !item.is_unread);
			if (unread.length > 15)
				return unread;
			return [...unread, ...read].slice(15);
		}
	}
}
</script>

<style lang="scss" scoped>
@import "@/src/styles/variables";

.media.is-unread {
	background-color: #e9e9e9;
}

.icon-bg-circle {
	font-size: 2em;
	float: left;
}

.float-right {
	float: right;
}

.media-list a {
	text-decoration: none;
}

.media-list>div {
	width: 100%;
}

.scrollable-container {
	overflow-y: auto;
}

.header-navbar .navbar-container .notifications-dropdown.dropdown-menu {
	left: -11rem !important;
}

@media screen and (max-width: $mobile) {
	.notifications-dropdown {
		width: min(24rem, 90vw) !important;
		right: -2rem !important;
		max-height: unset !important;
	}

	.notifications-dropdown .media-list {
		max-height: min(35rem, 70vh) !important;
	}
}</style>
