<template>
  <section class="py-32 md:py-48 lg:py-88">
    <div class="advisors-list" :class="advisor.cardName">
      <slot name="header"/>
      
      <div v-if="loading" class="flex justify-center py-88">
        <se-spinner/>
      </div>
      <div class="advisors-list-container">
        <article v-for="advisor of advisors" :key="advisor.id + advisor.url" class="w-full">
          <se-advisor-card :advisor="advisor"/>
        </article>
      </div>
      
      <slot name="footer"/>
    </div>
  </section>
</template>
<script setup lang="ts">
import {ref, onBeforeMount} from 'vue';
import {storeToRefs} from 'pinia';
import {getGlobalProperties} from '@/src/shared/helpers/getGlobalProperties';
import {useAdvisorListPaging} from "@/src/shared/helpers/advisorListPaging";
import {usersStore} from '@/src/store/users';
import SeAdvisorCard from '@/src/modules/advisor/components/advisor-card';
import SeSpinner from "@/src/shared/ui-kit/spinner/Spinner.vue";
import {theme} from "@/theme";

const {advisor} = theme.home
const {$api, $auth} = getGlobalProperties();

const users = usersStore();
const {currentUser, isAdvisor} = storeToRefs(users);

const advisors = ref<any[]>([]);
const loading = ref<boolean>(true);
const {perPage} = useAdvisorListPaging()

const fetchAdvisors = async (onlineOnly: string = '') => {
  advisors.value = [];
  loading.value = true
  await $api.get($auth?.check() ? '/api/user/advisors' : '/user/advisors', {
    sort: 'rankings',
    order: 'desc',
    limit: perPage.value,
    status: onlineOnly,
  })
    .then(async ({data}: any) => {
      advisors.value = data.data;
      
      if (advisors.value.length === 0 && !!onlineOnly) {
        await fetchAdvisors();
      }
    }).finally(() => loading.value = false);
};

onBeforeMount(() => {
  fetchAdvisors('online');
})
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorTopAdvisorsList',
};
</script>
