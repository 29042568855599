/** Wrapper for messaging librariy Pusher.
*/
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import config from '@/config'

class MessagingPusher {
    constructor(app, defaultOptions, userId, eventHandlers, channelEventNames, presenceChannel, publicChannel, privateChannel, clientOnlyEvents) {
        this.app = app
        this.defaultOptions = defaultOptions
        this.userId = userId
        this.eventHandlers = eventHandlers
        this.channelEventNames = channelEventNames
        this.presenceChannel = presenceChannel
        this.publicChannel = publicChannel
        this.privateChannel = privateChannel
        this.clientOnlyEvents = clientOnlyEvents

        this.pusher = null
    }

    init() {
        window.Pusher = Pusher

        let options = this.defaultOptions
        options.auth = {
            headers: {
                [config.api.authorizationHeaderName]: 'Bearer ' + this.app.$auth.headers.getToken()
            }
        }
        this.pusher = new Echo(options)

        for (let channelName in this.channelEventNames) {
            if (channelName == this.presenceChannel) {
                // Presence channels are processed in another way.
                let subscriber = this.pusher.join(channelName)
                let eventNames = [
                    'here',
                    'joining',
                    'leaving',
                ]
                for (let en in eventNames) {
                    let eventName = eventNames[en]
                    let handler = this.eventHandlers[eventName]
                    subscriber[eventName]((message) => {
                        let data = message
                        if (handler) {
                            for (let h in handler) {
                                handler[h](data)
                            }
                        } else {
                            console.error(`Handler for ${eventName} is not defined.`)
                        }
                    })
                }
            } else {
                let subscriber = (
                    (channelName == this.privateChannel)
                        ? this.pusher.private(channelName)
                        : this.pusher.channel(channelName)
                )
                let eventNames = this.channelEventNames[channelName]
                for (let en in eventNames) {
                    let eventName = eventNames[en]
                    let handler = this.eventHandlers[eventName]
                    let callback = function (message) {
                        let data = message
                        if (handler) {
                            for (let h in handler) {
                                handler[h](data)
                            }
                        } else {
                            console.error(`Handler for ${eventName} is not defined.`)
                        }
                    }
                    if (!this.clientOnlyEvents.includes(eventName)) {
                        subscriber.listen(eventName, callback)
                    } else {
                        // Doesn't really work well if the channel is private.
                        subscriber.listenForWhisper(eventName, callback)
                    }
                }
            }
        }
    }

    disconnect() {
        if (this.pusher) {
            for (let channelName in this.channelEventNames) {
                this.pusher.leave(channelName);
            }
            this.pusher = null
        }
    }

    sendMessage(options, callback) {
        let pusherResponse = this.pusher
            .private(options.channel)
            .whisper(options.event, options)
        if (typeof callback == 'function') {
            const status = {
                isError: false,
                errorMessage: '',
            }
            const response = pusherResponse

            callback(status, response)
        }
    }
}

export default MessagingPusher
