<template>
  <section class="pb-16">
    <h4 v-if="title" class="text-b-l font-bold pb-24">
      {{title}}
    </h4>
    
    <div class="overflow-x-scroll flex gap-8 pb-8">
      <article
        v-for="categoryItem in categories"
        class="px-16 py-10 rounded-8 gap-2 items-center bg-neutral-10 inline-flex shrink-0"
      >
        <suspense>
          <se-img
            v-if="categoryItem.category.icon"
            :alt="categoryItem.category.name"
            :src="`/static/images/category/${config.theme.name}/${categoryItem.category.icon}.svg`"
            :is-static="true"
            class="w-24 h-24"
          />
        </suspense>
        <span class="text-h-s text-center font-semibold text-neutral-100">
          {{ categoryItem.category.name }}
        </span>
      </article>
    </div>
  </section>
</template>

<script lang="ts" setup>
import config from "@/config";
import SeImg from "@/src/shared/ui-kit/img/Img.vue";
import {computed} from "vue";
import advisor from "@/src/modules/advisor/views/advisor.vue";

const props = defineProps<{
  advisor: any,
  title?: any,
}>();


const categories = computed(() => {
  return props.title && props.advisor.profile_categories?.length ? props.advisor.profile_categories : props.advisor.profile_categories?.slice(0,3)
})
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorCategories',
};
</script>
